html {
  min-height: 100%;
  overflow: overlay;
  margin: 0 auto;
}

.titleBar {
  position: fixed !important;
  width: 100% !important;
  padding-top: 0 !important;
  z-index: 999 !important;
  padding: 0.5rem 0 !important;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #6f42c1;
  border-radius: 10px;
  border: 3px solid transparent;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #6610f2;
}

.btn-close {
  -webkit-app-region: no-drag
}